import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ['video', 'progress']

  #mediaRecorder: MediaRecorder
  #videoElement: HTMLVideoElement

  async connect() {
    console.log('connected')
    this.#videoElement = document.querySelector('video')

    try {
      const displayMedia = await navigator.mediaDevices.getDisplayMedia({ video: true })
      this.#videoElement.srcObject = displayMedia
      this.#mediaRecorder = new MediaRecorder(displayMedia)
      const chunks = []
      this.#mediaRecorder.ondataavailable = (e) => {
        console.log('data available')
        chunks.push(e.data)
      }
      this.#mediaRecorder.onstop = (e) => {
        console.log('recording stopped')
        const blob = new Blob(chunks, { type: 'video/webm' })
        const url = URL.createObjectURL(blob)
        if (this.#videoElement.currentSrc?.startsWith('blob:')) {
          URL.revokeObjectURL(this.#videoElement.currentSrc)
        }
        this.#videoElement.src = url
        this.#videoElement.controls = true
        this.#videoElement.load()
        this.uploadFile(blob)
      }

      this.#mediaRecorder.start()
    } catch (error) {
      console.log(`Permission denied: ${error}`)
    }
  }

  uploadFile(blob: Blob) {
    const file = new File([blob], 'video.webm', { type: 'video/webm' })

    const upload = new DirectUpload(file, '/rails/active_storage/direct_uploads')
    upload.create((error, blob) => {
      if (error) {
        console.error(error)
      } else {
        this.createHiddenBlobInput(blob)
      }
    })
  }

  createHiddenBlobInput(blob: ActiveStorage.Blob) {
    const hiddenInput = document.createElement('input')
    hiddenInput.type = 'hidden'
    hiddenInput.name = 'blob'
    hiddenInput.value = blob.signed_id
    this.element.appendChild(hiddenInput)
  }
}
