import { Application } from "@hotwired/stimulus"
import { registerControllers } from "stimulus-vite-helpers"

const application = Application.start()

application.debug = true
window.Stimulus = application

const controllers = import.meta.glob("./**/*_controller.ts", { eager: true })
registerControllers(application, controllers)
