import '@hotwired/turbo'
import Alpine from 'alpinejs'
import * as ActiveStorage from '@rails/activestorage'

import '../controllers'

window.Alpine = Alpine

ActiveStorage.start()
Alpine.start()
